import { type GoogleData, GoogleProductName } from '../../googleIntegration';
import { originProductToAccountTypeMap } from '../constants';
import { type Details, Fields, type PayloadContractField } from '../types';

export interface BuildRequestPayloadArgs {
  googleData: GoogleData;
  details: Details;
}

export const buildRequestPayload = ({
  googleData,
  details: { [Fields.BUNDLES]: bundles, [Fields.DOMAINS]: domains, ...restOfContractData },
}: BuildRequestPayloadArgs): FormData => {
  const payload = new FormData();
  const contract: PayloadContractField = {
    ...restOfContractData,
    google_account_type: originProductToAccountTypeMap[googleData['origin-product']],
    google_network_code:
      googleData['origin-product'] === GoogleProductName.AD_MANAGER ? googleData['network-code'] : undefined,
    google_pub_id: googleData['publisher-id'],
  };
  delete contract[Fields.CONTRACT_SIGN_IN];

  payload.append('contract', JSON.stringify(contract));
  if (bundles) {
    payload.append('bundles', bundles);
  }
  if (domains) {
    payload.append('domains', domains);
  }

  return payload;
};
