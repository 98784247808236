import { Checkbox as MuiCheckbox, type CheckboxProps as MuiCheckboxProps, type Theme } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { type ForwardedRef, forwardRef } from 'react';

import { IconChecked, IconCheckedDisabled, IconIndeterminate, IconUnchecked, IconUncheckedDisabled } from './icons';

type MuiCheckboxWithErrorProps = MuiCheckboxProps & { isError?: boolean };

export const Checkbox = forwardRef(
  (props: MuiCheckboxWithErrorProps, ref: ForwardedRef<HTMLButtonElement>): JSX.Element => {
    const { isError, ...rest } = props;
    const theme = useTheme<Theme>();

    return (
      <MuiCheckbox
        color="primary"
        checkedIcon={props.disabled ? <IconCheckedDisabled /> : <IconChecked />}
        indeterminateIcon={<IconIndeterminate />}
        icon={
          props.disabled ? (
            <IconUncheckedDisabled />
          ) : (
            <IconUnchecked color={isError ? theme.palette.error.main : theme.palette.divider} />
          )
        }
        ref={ref}
        {...rest}
      />
    );
  }
);
