import { Typography, styled } from '@mui/material';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@openx/components/core/lib/Button/Button';
import {
  trackBackToGoogleConversion,
  trackConfirmationConversion,
  trackPageView,
} from '@openx/utils/lib/googleAnalytics';

import { ActionPaper } from 'components/ActionPaper';
import { Result, redirectToGoogle, sentGoogleResult } from 'modules/googleIntegration';

const StyledContentTypography = styled(Typography)`
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
`;

const StyledBackToGooleButton = styled(Button)`
  height: 40px;
  margin: ${({ theme }) => theme.spacing(4)} auto;
  width: 150px;
`;

const StyledContentEndTypography = styled(Typography)`
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  margin-top: ${({ theme }) => theme.spacing()};
`;

export function ConfirmationStep(): JSX.Element {
  const { t } = useTranslation();

  useEffect(() => {
    trackConfirmationConversion();
    trackPageView(window.location.href + window.location.search);
  }, []);

  useEffect(() => {
    sentGoogleResult(Result.SUCCESS, false);
  }, []);

  const backToGoogle = useCallback(() => {
    trackBackToGoogleConversion();
    redirectToGoogle(Result.SUCCESS);
  }, []);

  return (
    <ActionPaper>
      {() => (
        <>
          <Typography variant="h2" gutterBottom data-test="summary-title">
            {t('Success')}
          </Typography>
          <StyledContentTypography variant="h3" data-test="summary-subtitle">
            {t(
              'Please click button below to return to Google Ad Manager, acknowledge the partnership agreement was signed, and agree to the Open Bidding terms to work with our inventory.'
            )}
          </StyledContentTypography>
          <StyledBackToGooleButton color="primary" data-test="back-to-google-button" onClick={backToGoogle}>
            {t('Back to Google')}
          </StyledBackToGooleButton>
          <Typography data-test="summary-subtitle">
            <a
              href="https://community.openx.com/s/article/Google-OB-Online-Onboarding"
              target="_blank"
              rel="noopener noreferrer"
              data-test="documentation-url"
            >
              {t('Here')}{' '}
            </a>
            {t('you can find more information on what to expect next.')}
          </Typography>
          <StyledContentEndTypography data-test="summary-subtitle">
            {t('Thank you for choosing OpenX as a Google Open Bidding partner!')}
          </StyledContentEndTypography>
        </>
      )}
    </ActionPaper>
  );
}
