import { ANALYTICS_ENABLED } from 'config';

import { initialize as initializeHotjar } from './hotjar';

export async function initialize() {
  try {
    if (ANALYTICS_ENABLED) {
      initializeHotjar();
    }
  } catch (e) {
    // biome-ignore lint/suspicious/noConsole: this util logs the differences between expected and given data
    console.error('Failed on initializing analytics tools:', e);
    return;
  }
}
