import { Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import isPast from 'date-fns/isPast';
import { useCallback, useState } from 'react';

import { Button } from '../Button/Button';
import { DialogTitle } from '../Dialog/DialogTitle/DialogTitle';

import {
  SITE_UNAVAILABLE_POPUP_CLOSE,
  SITE_UNAVAILABLE_POPUP_EXPIRATION,
  SITE_UNAVAILABLE_POPUP_FLAG,
  SITE_UNAVAILABLE_POPUP_MESSAGE,
  SITE_UNAVAILABLE_POPUP_TITLE,
} from './constants';

const siteUnavailablePopupConfirmed = sessionStorage.getItem(SITE_UNAVAILABLE_POPUP_FLAG) ?? false;

export const SiteUnavailablePopup = () => {
  const isExpired = isPast(SITE_UNAVAILABLE_POPUP_EXPIRATION);
  const [isOpen, setIsOpen] = useState(!siteUnavailablePopupConfirmed && !isExpired);

  const onClose = useCallback(() => {
    sessionStorage.setItem(SITE_UNAVAILABLE_POPUP_FLAG, '1');
    setIsOpen(false);
  }, []);

  return (
    <Dialog open={isOpen} onClose={onClose} data-test="site-unavailable-popup">
      <DialogTitle onClose={onClose}>{SITE_UNAVAILABLE_POPUP_TITLE}</DialogTitle>
      <DialogContent dividers>
        <Typography data-test="site-unavailable-modal-info" gutterBottom>
          {SITE_UNAVAILABLE_POPUP_MESSAGE}
        </Typography>
      </DialogContent>

      <DialogActions data-test="site-unavailable-dialog-action-buttons">
        <Button data-test="site-unavailable-close-button" onClick={onClose} variant="text">
          {SITE_UNAVAILABLE_POPUP_CLOSE}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
