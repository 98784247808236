import { Grid, Typography, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface PromoBoxI {
  subtitle: string;
  text: string;
}

interface PromoBoxProps {
  promoBoxList: PromoBoxI[];
}

const StyledTitleTypography = styled(Typography)`
  font-size: 15px;
  line-height: 20px;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

const StyledSubtitleTypography = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.secondary};
  font-weight: 600;
  margin-bottom: ${({ theme }) => theme.spacing()};
`;

const StyledTextTypography = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.secondary};
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

export function PromoBox({ promoBoxList }: PromoBoxProps): JSX.Element | null {
  const { t } = useTranslation();

  if (!promoBoxList.length) {
    return null;
  }

  return (
    <>
      <StyledTitleTypography variant="h3" data-test="promo-box-title">
        {t('OpenX Publisher Benefits')}
      </StyledTitleTypography>
      {promoBoxList.map(({ subtitle, text }) => (
        <Grid key={subtitle}>
          <StyledSubtitleTypography variant="body1" data-test="promo-box-subtitle">
            {subtitle}
          </StyledSubtitleTypography>
          <StyledTextTypography variant="body1" data-test="promo-box-text">
            {text}
          </StyledTextTypography>
        </Grid>
      ))}
    </>
  );
}
