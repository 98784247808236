import i18n, { type InitOptions } from 'i18next';
import Backend from 'i18next-chained-backend';
import HttpApi from 'i18next-http-backend'; // fallback http load
import LocalStorageBackend from 'i18next-localstorage-backend'; // primary use cache
import { initReactI18next } from 'react-i18next';

import { getXOpenxInstance } from '../lib/getXOpenxInstance';
import { getOIDCAuthInfoFromStorageSync } from '../lib/oidc/getOIDCAuthInfoFromStorage';

import { APIGEE_TRANSLATE_BACKEND_URL, TRANSLATE_BACKEND_URL } from './constants';
import type { BackendConfig, i18nConfigureProps } from './types';

const getBackendConfig = ({ prefix, version }: BackendConfig) => {
  const oidcAuthInfo = getOIDCAuthInfoFromStorageSync();

  return {
    backendOptions: [
      {
        // options for LocalStorageBackend
        defaultVersion: version,
        prefix,
      },
      {
        // options for HttpApi
        customHeaders: () =>
          oidcAuthInfo
            ? {
                Authorization: `Bearer ${oidcAuthInfo.accessToken}`,
                'x-openx-instance': getXOpenxInstance(),
              }
            : {},
        loadPath: oidcAuthInfo ? APIGEE_TRANSLATE_BACKEND_URL : TRANSLATE_BACKEND_URL,
      },
    ],
    backends: [
      LocalStorageBackend, // primary
      HttpApi, // fallback
    ],
  };
};

export const i18nConfigure = ({ backendOptions, lng, resources, supportedLngs }: i18nConfigureProps = {}) => {
  // for all-options read: https://www.i18next.com/overview/configuration-options
  const initOptions: InitOptions = {
    backend: backendOptions && getBackendConfig(backendOptions),
    // enable debug mode
    // debug: true,
    interpolation: { escapeValue: false, prefix: '{', suffix: '}' },
    // disable key separator to allow using keys like: "exception.size.characters"
    keySeparator: false,
    // language to use; 'cimode' - the output text will be the key, useful for backend testing
    lng,
    load: 'currentOnly',
    // disable ns separator to allow using keys like: "edit: {name}" as we don't use namespaces right now
    nsSeparator: false,
    // Disable Suspense because it is an experimental feature
    react: {
      useSuspense: false,
    },
    resources,
    supportedLngs,
  };

  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  if (backendOptions) {
    i18n.use(Backend);
  }

  i18n
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init(initOptions);
};
