import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box, Chip, CircularProgress, IconButton } from '@mui/material';
import MuiAutocomplete from '@mui/material/Autocomplete';
import { styled } from '@mui/material/styles';
import type { AutocompleteInputChangeReason } from '@mui/material/useAutocomplete/useAutocomplete';
import { useCallback, useMemo, useState } from 'react';
import { useController } from 'react-hook-form';
import { DebounceField } from '../DebounceField/DebounceField';
import { TextField } from '../TextField/TextField';
import { AutoCompleteRenderOptions } from './AutoCompleteRenderOptions';
import type { RHFAutocompleteProps, RenderOptions } from './types';

export const RHFAutoComplete = <
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  name,
  control,
  textFieldProps,
  loading,
  debounced = false,
  onDebounceChange,
  onOptionBlur,
  renderOptions,
  onInputChange,
  ChipProps,
  isOptionEqualToValue,
  value,
  onChange,
  getOptionKey,
  ...rest
}: RHFAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>): JSX.Element => {
  const {
    field: { onChange: fieldOnChange, ...restFieldProps },
    fieldState: { invalid, error },
    formState: { isSubmitting },
  } = useController({
    control,
    name,
  });
  const [inputValue, setInputValue] = useState('');

  const CommonProps = useMemo(
    () => ({
      ChipProps: {
        'data-test': 'chip-button',
        deleteIcon: (
          <IconButton size="large">
            <CloseIcon data-test="chip-button-delete-icon" />
          </IconButton>
        ),
        ...ChipProps,
      },
      disabledItemsFocusable: true,
      limitTags: 1,
      multiple: undefined,
      popupIcon: <KeyboardArrowDownIcon />,
    }),
    [ChipProps]
  );

  const TextInputComponent = useMemo(() => (debounced ? DebounceField : TextField), [debounced]);

  const handleInputChange = useCallback(
    (e, newValue: string, reason: AutocompleteInputChangeReason) => {
      if (reason === 'reset') {
        setInputValue('');
      } else {
        setInputValue(newValue);
      }
      onInputChange?.(e, newValue, reason);
    },
    [onInputChange]
  );

  const getOptionValue = useCallback(
    (
      option: T,
      getOptionValue?: RenderOptions<T>['getOptionValue'],
      getOptionLabel?: RenderOptions<T>['getOptionValue']
    ) => {
      if (getOptionValue) return getOptionValue(option);
      if (getOptionLabel) return getOptionLabel(option);

      return typeof option === 'string' ? option : '';
    },
    []
  );

  const getDataTest = useCallback((option: T, dataTest?: string | ((option: T) => string)) => {
    return typeof dataTest === 'string' ? dataTest : dataTest?.(option);
  }, []);

  const hasMultiple = useMemo(() => rest.multiple === true, [rest.multiple]);

  const onOptionChange = useCallback(
    (_, value, reason, details) => {
      onChange ? onChange(_, value, reason, details) : fieldOnChange(value);
    },
    [fieldOnChange, onChange]
  );

  const onBlur = useCallback(() => {
    restFieldProps.onBlur();
    onOptionBlur?.();
  }, [restFieldProps, onOptionBlur]);

  const renderOption = useCallback(
    (state, option: T, { selected }) => {
      return (
        <li {...state} key={getOptionKey ? getOptionKey(option) : option}>
          <AutoCompleteRenderOptions
            option={getOptionValue(option, renderOptions?.getOptionValue, rest?.getOptionLabel)}
            childrenLocation={renderOptions?.childrenLocation}
            dataTest={getDataTest(option, renderOptions?.dataTest)}
            phraseToHighlight={inputValue}
            getPaddingLevel={() => renderOptions?.getPaddingLevel?.(option)}
            tooltip={renderOptions?.tooltip}
            tooltipPlacement={renderOptions?.tooltipPlacement}
            tooltipMessage={renderOptions?.tooltipMessage?.(option)}
            hasMultiple={hasMultiple}
            selected={selected}
          >
            {renderOptions?.renderChildren?.(option)}
          </AutoCompleteRenderOptions>
        </li>
      );
    },
    [getDataTest, getOptionKey, getOptionValue, hasMultiple, inputValue, renderOptions, rest?.getOptionLabel]
  );

  const renderInput = useCallback(
    ({ InputLabelProps, InputProps, ...restParams }) => (
      // @ts-ignore
      <TextInputComponent
        onChange={e => onDebounceChange?.(e)}
        {...restParams}
        {...textFieldProps}
        key={restParams.key}
        InputLabelProps={{ disableAnimation: true, focused: false, shrink: true, ...InputLabelProps }}
        InputProps={{
          disableUnderline: true,
          ...InputProps,
          endAdornment: (
            <>
              {loading && (
                <Box pr={1}>
                  <CircularProgress data-test="autocomplete-loading-indicator" size={20} />
                </Box>
              )}
              {InputProps.endAdornment}
            </>
          ),
          readOnly: rest.textFieldReadOnly,
        }}
        helperText={
          (Array.isArray(error) && error?.find(value => value !== undefined)?.message) ||
          error?.message ||
          textFieldProps?.helperText
        }
        error={invalid || textFieldProps?.error}
      />
    ),
    [TextInputComponent, error, invalid, loading, onDebounceChange, rest.textFieldReadOnly, textFieldProps]
  );

  return (
    <StyledAutocomplete
      {...restFieldProps}
      {...CommonProps}
      value={typeof value !== 'undefined' ? value : restFieldProps.value}
      onChange={onOptionChange}
      onBlur={onBlur}
      disableCloseOnSelect={hasMultiple}
      isOptionEqualToValue={isOptionEqualToValue}
      renderOption={renderOption}
      // Custom Tags render due to bug in MUI solved from MUI 6: https://github.com/mui/material-ui/pull/42099
      renderTags={(value: T[], getTagProps) => {
        return value.map((option: T, index: number) => {
          const { key, ...tagProps } = getTagProps({ index });
          return (
            <Chip
              label={getOptionValue(option, renderOptions?.getOptionValue, rest?.getOptionLabel)}
              key={key}
              {...tagProps}
              {...CommonProps.ChipProps}
            />
          );
        });
      }}
      onInputChange={handleInputChange}
      {...rest}
      renderInput={renderInput}
      disabled={isSubmitting || rest.disabled}
    />
  );
};

const StyledAutocomplete = styled(MuiAutocomplete, { shouldForwardProp: prop => prop !== 'textFieldReadOnly' })<{
  textFieldReadOnly?: boolean;
}>`
  & .MuiAutocomplete-input {
    cursor: ${({ textFieldReadOnly }) => (textFieldReadOnly ? 'pointer' : 'auto')};
  }
` as typeof MuiAutocomplete;
