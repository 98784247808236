import { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';

import {
  ALLOWED_DOMAINS,
  ALLOWED_DOMAINS_GROUP,
  GOOGLE_MOCKED_DATA,
  GOOGLE_VERSION,
  MSG_ORIGIN_DISABLED,
} from 'config';

import schema from './schema';
import {
  type AckMessage,
  type AckMsgType,
  type CommonMessage,
  type GoogleData,
  type GoogleIntegrationState,
  GoogleProductName,
  type InitMessage,
  type Listener,
  MsgType,
  Result,
  type ResultData,
} from './types';

const isOriginValid = (origin: string) => ALLOWED_DOMAINS.includes(origin) || origin.endsWith(ALLOWED_DOMAINS_GROUP);

const dataListeners = new Set<Listener>();
let error: Error;
let receivedData: GoogleData;
let receivedDataOrigin;

const getCommonMsgFromData = (data: GoogleData, type: MsgType): CommonMessage => {
  return {
    'origin-product': data['origin-product'],
    'publisher-id': data['publisher-id'],
    token: data.token,
    type,
    version: GOOGLE_VERSION,
  };
};

const getAckMsgFromGoogleData = (data: GoogleData, ackType: AckMsgType): AckMessage => {
  return {
    ...getCommonMsgFromData(data, MsgType.ACK),
    'ack-type': ackType,
  };
};

export const useGoogleIntegration = (): GoogleIntegrationState => {
  const [state, setState] = useState<GoogleIntegrationState>({
    data: receivedData,
    error,
    pending: !(error || receivedData),
  });
  useEffect(() => {
    if (error || receivedData) return;

    const listener = (error, data) => {
      setState({ data, error, pending: false });
    };
    dataListeners.add(listener);
    return () => {
      dataListeners.delete(listener);
    };
  }, []);
  return state;
};

export const initialize = () => {
  if (GOOGLE_MOCKED_DATA) {
    receivedData = {
      'network-code': '1234567890',
      'origin-product': GoogleProductName.AD_MANAGER,
      'publisher-id': `pub-${Math.random().toString().slice(2, 10)}`,
      'redirect-url': 'https%3A%2F%2Fadmanager.google.com%2F123456789%23home',
      token: 'iuIU7a8rgayasf87',
      type: MsgType.DATA_POINTS,
      version: GOOGLE_VERSION,
    };
  }

  if (!window.opener) return;

  const messageListener = (event: MessageEvent) => {
    if (!MSG_ORIGIN_DISABLED && !isOriginValid(event.origin)) return;
    window.removeEventListener('message', messageListener);
    try {
      schema.validateSync(event.data);
      receivedData = event.data;
      receivedDataOrigin = event.origin;
      if ([MsgType.DATA_POINTS, MsgType.RESULT, MsgType.TEST_NEXT].includes(receivedData.type)) {
        /*
        This message is sent by:
        - The bidder window after receiving the DATA_POINTS message
        - The Google window after receiving the RESULT message
        - The bidder window after receiving the next step message, optionally (Testing).
        */
        const ackMessage: AckMessage = getAckMsgFromGoogleData(receivedData, receivedData.type as AckMsgType);
        (event.source as Window).postMessage(ackMessage, event.origin);
      }
    } catch (e) {
      error = e;
      // biome-ignore lint/suspicious/noConsole: this util logs the differences between expected and given data
      console.error('Unable to properly handle received data:', e);
    }

    dataListeners.forEach(cb => cb(error, receivedData));
    dataListeners.clear();
  };
  window.addEventListener('message', messageListener);

  const fakeToken = uuid(); // TODO: UIG-540 Use real token once we know more about it
  const initMessage: InitMessage = {
    token: fakeToken,
    type: MsgType.DATA_POINTS_REQUEST,
    version: GOOGLE_VERSION,
  };
  window.opener.postMessage(initMessage, '*');
};

const getResultData = (result: Result): ResultData => ({
  ...getCommonMsgFromData(receivedData, MsgType.RESULT),
  'keep-window-open': result === Result.SUCCESS,
  'result-value': result,
});

export const redirectToGoogle = (result: Result) => {
  const data: ResultData = getResultData(result);
  const redirectionUrl = decodeURIComponent(receivedData['redirect-url']);
  const url = new URL(redirectionUrl);
  url.search = new URLSearchParams(data as Record<string, any>).toString();
  window.location.replace(url.toString());
};

export const sentGoogleResult = (result: Result, enableRedirect = true) => {
  if (!receivedData) return;
  const data: ResultData = getResultData(result);

  if ((!window.opener || window.opener.closed) && !GOOGLE_MOCKED_DATA) {
    redirectToGoogle(result);
    return;
  }

  const messageListener = (event: MessageEvent) => {
    if (!isOriginValid(event.origin) || event.data['ack-type'] !== MsgType.RESULT) return;
    clearTimeout(timeout);
    enableRedirect && redirectToGoogle(result);
  };

  const timeout = setTimeout(() => {
    window.removeEventListener('message', messageListener);
    enableRedirect && redirectToGoogle(result);
  }, 500);
  window.addEventListener('message', messageListener);

  !GOOGLE_MOCKED_DATA && window.opener.postMessage(data, receivedDataOrigin);
};
