import { styled } from '@mui/material/styles';

import { defaultPadding, paddingMap } from './config';
import type { IndentationProps, PaperPadding } from './types';

const StyledDiv = styled('div')<{ padding: string }>(({ theme, padding }) => ({
  background: theme.palette.background.boxLight,
  margin: `0 -${paddingMap[padding]}px`,

  padding: paddingMap[padding],
}));

export const makeIndentation = (padding: PaperPadding = defaultPadding) =>
  function ({ children, className }: IndentationProps): JSX.Element {
    return (
      <StyledDiv padding={padding} className={className}>
        {children}
      </StyledDiv>
    );
  };
