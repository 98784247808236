import { OpenXLogo } from './components/OpenXLogo';
import { OpenXSelectLogo } from './components/OpenXSelectLogo';
import type { LogoProps } from './utils/interfaces';

export function Logo(props: Readonly<LogoProps>): JSX.Element {
  const { darkMode, isOA } = props;

  if (isOA) {
    return <OpenXSelectLogo darkMode={darkMode} data-test={props['data-test']} />;
  }

  return <OpenXLogo darkMode={darkMode} data-test={props['data-test']} />;
}
