import { Card, CardContent, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';
import { type ComponentType, type ReactNode, useEffect } from 'react';

import { Button, type ButtonProps } from '../Button/Button';

const StyledInfoContainerDiv = styled('div')`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  padding-left: 10%;
`;

const StyledTitleDiv = styled('div')`
  font-size: 48px;
  font-weight: 800;
  line-height: 64px;
  margin-bottom: 10px;
`;

const StyledTypography = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.secondary};
`;

const StyledActionSectionDiv = styled('div')`
  margin: ${({ theme }) => theme.spacing(3.75, 0)};

  & > button {
    margin: ${({ theme }) => theme.spacing(0, 1, 0, 0)};
  }
`;

const StyledPictureContainerDiv = styled('div')`
  align-items: flex-end;
  display: flex;
  justify-content: flex-end;

  & > svg {
    max-height: 100%;
    max-width: 100%;
  }
`;

export interface ErrorProps {
  title: ReactNode;
  subtitle?: ReactNode;
  actions?: ButtonProps[];
  PictureComponent?: ComponentType;
  className?: string;
  'data-test'?: string;
  titlePrefix?: string;
  devDebug?: string;
  withHeader?: boolean;
}

export function ErrorComponent(props: ErrorProps): JSX.Element {
  const { title, titlePrefix, subtitle, actions, PictureComponent, className, devDebug, withHeader = true } = props;

  useEffect(() => {
    document.title = titlePrefix ? `${titlePrefix} - ${title}` : `${title}`;
  }, [title, titlePrefix]);

  return (
    <Box
      className={className}
      data-test={props['data-test']}
      sx={[
        {
          display: 'flex',
          width: '100%',
        },
        withHeader && {
          height: 'calc(100vh - 54px)',
        },
        !withHeader && {
          height: '100vh',
        },
      ]}
    >
      <StyledInfoContainerDiv>
        <StyledTitleDiv>{title}</StyledTitleDiv>
        <StyledTypography variant="body1">{subtitle}</StyledTypography>
        <StyledActionSectionDiv>
          {actions?.map((buttonProps, index) => (
            <Button key={index} {...buttonProps} />
          ))}
        </StyledActionSectionDiv>
      </StyledInfoContainerDiv>
      {devDebug && (
        <StyledInfoContainerDiv>
          <Card sx={{ minWidth: 600 }}>
            <CardContent>
              <Typography variant="body1">{devDebug}</Typography>
            </CardContent>
          </Card>
        </StyledInfoContainerDiv>
      )}
      <StyledPictureContainerDiv>{PictureComponent && <PictureComponent />}</StyledPictureContainerDiv>
    </Box>
  );
}
