import type { TFunction } from 'i18next';
import { array, boolean, mixed, object, string } from 'yup';

import { isUrlValid } from '@openx/utils/lib/isUrlValid';

import { AccountType, Fields } from '../types';

const isCsvFile = (file: File) => file.name.endsWith('.csv');

const validateEmails = (value: (string | undefined)[] = []) =>
  array().of(string().email().required()).isValidSync(value);

export const getValidationSchema = (accountType: AccountType, t: TFunction) => {
  return object().shape(
    {
      [Fields.LEGAL_NAME]: string().required(t('Company Name is required')),
      [Fields.WEBSITE]: string()
        .required(t('Company Website is required'))
        .test('url-validness', t('Company Website must be a valid URL'), isUrlValid),
      [Fields.EMAILS]: array()
        .of(string())
        .test('is-email-valid', t('Some of the above emails is invalid'), validateEmails)
        .min(1, t('Email is required'))
        .required(t('Email is required')),
      [Fields.BUNDLES]: mixed()
        .test('isCsv', t('The format of the file is not valid. Only CSV files are supported.'), (file: File | null) => {
          if (file) {
            return isCsvFile(file);
          }

          return true;
        })
        .test('isBundleRequired', t('Bundles file is required'), (file: File | null) => {
          if (!file && accountType === AccountType.ADMOB) {
            return false;
          }

          return true;
        })
        .when(Fields.DOMAINS, {
          is: (file: File | null) => !file && accountType === AccountType.GAM,
          then: schema => schema.required(t('At least one of domains and bundles files has to be provided')),
        }),
      [Fields.DOMAINS]: mixed()
        .test('isCsv', t('The format of the file is not valid. Only CSV files are supported.'), (file: File | null) => {
          if (file) {
            return isCsvFile(file);
          }

          return true;
        })
        .when(Fields.BUNDLES, {
          is: (file: File | null) => !file && accountType === AccountType.GAM,
          then: schema => schema.required(t('At least one of domains and bundles files has to be provided')),
        }),
      [Fields.CONTRACT_SIGN_IN]: boolean().oneOf([true], t('Field must be checked')),
    },
    [[Fields.BUNDLES, Fields.DOMAINS]]
  );
};
