import { Box, type BoxProps, Divider } from '@mui/material';
import { useMemo } from 'react';

import { defaultPadding, paddingMap, verticalPaddingFactor } from './config';
import type { HighlightedSectionProps, PaperPadding } from './types';

const makeIndentation =
  (padding: PaperPadding = defaultPadding) =>
  (props: BoxProps): JSX.Element => <Box margin={`0 -${paddingMap[padding]}px`} {...props} />;

export const makeActionsSection = (padding: PaperPadding = defaultPadding) =>
  function ({ children }: HighlightedSectionProps): JSX.Element {
    const Indentation = useMemo(() => makeIndentation(padding), []);

    return (
      <Indentation
        sx={{
          marginBottom: `${-(paddingMap[padding] * verticalPaddingFactor)}px`,
          marginTop: `${paddingMap[padding] * verticalPaddingFactor}px`,
        }}
      >
        <Divider />
        <Box bgcolor="background.boxLight" padding={theme => `${theme.spacing(1)} ${paddingMap[padding]}px`}>
          {children}
        </Box>
      </Indentation>
    );
  };
