import type { ButtonProps as MuiButtonProps } from '@mui/material/Button';

import { ButtonAction } from './ButtonAction';
import { ButtonLink } from './ButtonLink';

export interface ButtonProps extends Omit<MuiButtonProps, 'color'> {
  color?: MuiButtonProps['color'] | 'default';
  icon?: boolean;
  loading?: boolean;
  allowed?: boolean;
  notAllowedMessage?: string;
  'data-test'?: string | ((row: any) => string);
}

export function Button(props: ButtonProps): JSX.Element {
  if (props.href) {
    return <ButtonLink href={props.href} {...props} />;
  }

  return <ButtonAction {...props} />;
}
