import type { i18nTranslate } from '@openx/types/translations';

import { EN_LOCALE, JP_LOCALE, LANGUAGES, LANGUAGE_LOCAL_STORAGE_KEY } from './constants';
import type { Locale } from './types';

export const checkLocale = (locale: string, languages: string[] = LANGUAGES) => {
  if (!locale || !languages.includes(locale)) {
    return EN_LOCALE;
  }

  return locale;
};

export const getCurrentLocale = () =>
  window.localStorage.getItem(LANGUAGE_LOCAL_STORAGE_KEY) || navigator.language || navigator.languages[0];

export const getLocales = (t: i18nTranslate): Locale[] => [
  { id: EN_LOCALE, name: t('English / United States') },
  { id: JP_LOCALE, name: t('Japanese') },
];
