import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { type ReactNode, useEffect } from 'react';

const StyledContainerDiv = styled('div')`
  background: linear-gradient(
    93.1deg,
    ${({ theme }) => theme.palette.primary.main} 16.27%,
    ${({ theme }) => theme.palette.secondary.main} 74.56%
  );
  color: ${({ theme }) => theme.palette.common.white};
  display: flex;
  flex-direction: column;
  height: 111px;
  justify-content: 'flex-end';
  margin-bottom: ${({ theme }) => theme.spacing(-6.25)};
  padding: ${({ theme }) => theme.spacing(1.5, 4, 7.75)};
`;

const StyledHeaderContainerGrid = styled(Grid)`
  bottom: 3px;
  position: relative;
`;

const StyledHeaderTypography = styled(Typography)`
  bottom: -3px;
  left: 0;
  position: absolute;
`;

const StyledContentContainerDiv = styled('div')`
  padding: ${({ theme }) => theme.spacing(0, 4, 4, 4)};
`;

export type GridJustification =
  | 'flex-start'
  | 'center'
  | 'flex-end'
  | 'space-between'
  | 'space-around'
  | 'space-evenly';

export interface PageHeaderProps {
  children?: ReactNode;
  title?: string;
  render?: () => ReactNode;
  headerJustify?: GridJustification;
  titlePrefix?: string;
}

export function PageHeader(props: PageHeaderProps): JSX.Element {
  const { children, title, titlePrefix, render, headerJustify = 'flex-end' } = props;

  useEffect(() => {
    document.title = `${titlePrefix || 'Unity'}  ${title && `- ${title}`}`;
  }, [title, titlePrefix]);

  return (
    <>
      <StyledContainerDiv data-test="page-header">
        <StyledHeaderContainerGrid container justifyContent={headerJustify}>
          {title && (
            <StyledHeaderTypography variant="h1" data-test="page-header-title">
              {title}
            </StyledHeaderTypography>
          )}
          {render?.()}
        </StyledHeaderContainerGrid>
      </StyledContainerDiv>
      <StyledContentContainerDiv>{children}</StyledContentContainerDiv>
    </>
  );
}
