import { ErrorResponse } from 'oidc-client-ts';

import { getEnvVariables } from '../../services/optionsService';
import { getXOpenxInstance } from '../getXOpenxInstance';

import { type OIDCAuthInfo, isOIDCAuthInfoExpired, loadOIDCAuthInfo, storeOIDCAuthInfo } from './oidcAuthInfo';

const GRANT_TYPE = 'urn:ietf:params:oauth:grant-type:token-exchange';
const REQUESTED_TOKEN_TYPE = 'urn:ietf:params:oauth:token-type:access_token';
const SUBJECT_TOKEN_TYPE = 'urn:ietf:params:oauth:token-type:id_token';
const ISSUED_TOKEN_TYPE = 'urn:ietf:params:oauth:token-type:access_token';
const SCOPE = 'api';

type ExchangeTokenRequest = {
  grant_type: typeof GRANT_TYPE;
  requested_token_type: typeof REQUESTED_TOKEN_TYPE;
  subject_token_type: typeof SUBJECT_TOKEN_TYPE;
  scope: string;
  client_id: string;
  subject_token: string;
  authorization_details: string;
};

type AuthorizationDetail = {
  type: string;
  [key: string]: unknown;
};

type ExchangeTokenResponseSuccess = {
  access_token: string;
  authorization_details: AuthorizationDetail[];
  issued_token_type: typeof ISSUED_TOKEN_TYPE;
  scope: string;
  expires_in: number;
  token_type: string;

  error: never;
  error_description: never;
};

type RevokeAccessTokenRequest = {
  token: string;
  client_id: string;
};

export async function exchangeIdToken(
  tokenExchangeEndpoint: string,
  clientId: string,
  idToken: string,
  hostname: string
): Promise<OIDCAuthInfo> {
  const data: ExchangeTokenRequest = {
    authorization_details: JSON.stringify([
      {
        identifier: hostname,
        type: 'instance_hostname',
      },
    ]),
    client_id: clientId,
    grant_type: GRANT_TYPE,
    requested_token_type: REQUESTED_TOKEN_TYPE,
    scope: SCOPE,
    subject_token: idToken,
    subject_token_type: SUBJECT_TOKEN_TYPE,
  };

  const response = await fetch(tokenExchangeEndpoint, {
    body: new URLSearchParams(data),
    method: 'POST',
  });

  const json = (await response.json()) as ExchangeTokenResponseSuccess;

  if (json.error) {
    throw new ErrorResponse(json);
  }

  const { authorization_details, access_token, expires_in } = json;
  const instanceUidObj = authorization_details.find(value => value.type === 'instance_uid');

  if (!instanceUidObj) {
    throw new ErrorResponse({
      error: 'no_instance_uid',
      error_description: 'No instance uid in response',
    });
  }

  const instanceUid = instanceUidObj['identifier'] as string;

  if (!instanceUid) {
    throw new ErrorResponse({
      error: 'no_instance_uid',
      error_description: 'No instance uid in response',
    });
  }

  return {
    accessToken: access_token,
    expirationTime: Date.now() + expires_in * 1000,
    instanceUid,
  };
}

export async function revokeExchangeAccessToken(
  ssoAuthority: string,
  clientId: string,
  accessToken: string
): Promise<void> {
  const data: RevokeAccessTokenRequest = {
    client_id: clientId,
    token: accessToken,
  };

  const revokeTokenEndpoint = ssoAuthority + '/oauth2/v1/revoke';
  try {
    const response = await fetch(revokeTokenEndpoint, {
      body: new URLSearchParams(data),
      method: 'POST',
    });

    if (response.status !== 200) {
      // eslint-disable-next-line no-console
      console.warn(`Revoking access token failed: ${response.status}:${response.statusText}`);
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.warn(`Can't revoke access token: "${err.message}"`);
  }
}

export async function exchangeOIDCAuthInfo(idToken: string): Promise<OIDCAuthInfo> {
  const { ssoAuthority, ssoClientId } = getEnvVariables();
  if (!ssoAuthority || !ssoClientId) {
    throw new Error('SSO provider is not configured');
  }
  // FIXME: probably should go to env variables
  const tokenExchangeEndpoint = ssoAuthority + '/oauth2/v1/token';
  const savedOIDCAuthInfo = loadOIDCAuthInfo(ssoAuthority, ssoClientId);
  if (!isOIDCAuthInfoExpired(savedOIDCAuthInfo)) {
    return savedOIDCAuthInfo;
  }
  const hostname = getXOpenxInstance();
  const newOIDCAuthInfo = await exchangeIdToken(tokenExchangeEndpoint, ssoClientId, idToken, hostname);
  storeOIDCAuthInfo(ssoAuthority, ssoClientId, newOIDCAuthInfo);
  return newOIDCAuthInfo;
}
