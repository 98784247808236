import { type Theme, css } from '@mui/material/styles';
import type { SerializedStyles } from '@mui/styled-engine';

import { getImpersonationInformation } from './../components/core/src/lib/ImpersonationBar/utils';
import { getNavbarHeight } from './../components/core/src/lib/Navbar/utils';

const HEADER_HEIGHT = 80;

export const TOP_BAR_HEIGHT =
  process.env.NX_PUBLIC_REACT_APP_TOP_BAR_HEIGHT !== undefined
    ? parseInt(process.env.NX_PUBLIC_REACT_APP_TOP_BAR_HEIGHT + '', 10)
    : 54;

export const globalStyles = (theme: Theme, header?: string): SerializedStyles => css`
  * {
    font-family: 'Open Sans', sans-serif;
    margin: 0;
    padding: 0;
  }

  body {
    min-width: 1280px;
  }

  a {
    color: ${theme.palette.primary.main};
    text-decoration: none;
  }

  ${header ?? ''}
`;

export const headerStyles = `
  &:root {
    --content-top-offset: ${HEADER_HEIGHT + getNavbarHeight(!!getImpersonationInformation()) + 1}px;
    --header-height: ${HEADER_HEIGHT}px;
    --topbar-height: ${getNavbarHeight(!!getImpersonationInformation())}px;
  }
`;

export const internalHeaderStyles = `
  &:root {
    --content-top-offset: ${HEADER_HEIGHT + TOP_BAR_HEIGHT + 1}px;
    --header-height: ${HEADER_HEIGHT}px;
    --topbar-height: ${TOP_BAR_HEIGHT}px;
  }
`;
